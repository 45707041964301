import { OnUpdateEntitySubscription } from '~/../api.types';
import {
  LandingpageList,
  LandingpageObject,
  ParsedLandingpageObject,
  UpdatePageParams,
} from '~/global.types';
import ApiClient from '~/helpers/ApiClient';
import { callGraphQl, subscribeGraphQl } from '~/helpers/graphQl/graphQl';
import msg from '~/helpers/viewerInteractions/msg';

export const awsAPIClient = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);

export const publishPage = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.post<{ message: string }>('/publish-page', {
    nanoId,
  });
};

export const deletePage = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.delete<{ message: string }>('/delete-page', {
    nanoId,
  });
};

export const listPages = async ({ workspaceId }: { workspaceId: string }) => {
  return awsAPIClient.post<{ data: LandingpageList }>('/list-pages', {
    workspaceId,
  });
};

export const copyPage = async ({
  nanoId,
  name,
  slug,
}: {
  nanoId: string;
  name: string;
  slug: string;
}) => {
  return awsAPIClient.post<{
    message: string;
    data: { nanoId: string; name: string; definition: string };
  }>('/duplicate-page', {
    nanoId,
    name,
    slug,
  });
};

interface PageSlugExistsParams {
  workspaceId: string;
  slug: string;
  nanoId?: string;
}

interface Subscribe {
  unsubscribe: () => void;
}

export const doesPageSlugExist = async ({ workspaceId, slug, nanoId }: PageSlugExistsParams) => {
  return awsAPIClient.post<{ exists: boolean }>('/page-slug-exists', {
    workspaceId,
    slug,
    nanoId,
  });
};

export const doesPageNameExist = async ({
  workspaceId,
  name,
}: {
  workspaceId: string;
  name: string;
}) => {
  return awsAPIClient.post<{ exists: boolean }>('/page-name-exists', {
    workspaceId,
    name,
  });
};

const expectedEntities = `
  nanoId
  definition
  content
  workspaceId: relatedEntityId
  name
  title
  description
  gtmId
  favicon
  status
`;

export const prettyJson = (json?: Record<string, unknown>) => JSON.stringify(json ?? '{}', null, 2);

export const firstFetch = async (nanoId?: string): Promise<ParsedLandingpageObject> => {
  const page = await awsAPIClient.post<LandingpageObject>('/get-page', {
    nanoId,
  });
  return {
    ...page,
    content: prettyJson(page?.content),
  } as ParsedLandingpageObject;
};

export const updatePage = async (workspaceId: string, nanoId: string, page: UpdatePageParams) => {
  try {
    return await awsAPIClient.put('/update-page', {
      workspaceId,
      nanoId,
      ...page,
    });
  } catch (error) {
    let message = 'Failed to update page';
    if (error instanceof Error) {
      message = error.message;
    }
    msg({
      type: 'update-page-content-failed',
      outputData: { errorMessage: message ?? 'Failed to render page' },
    });
  }
};

export interface updatePageParams {
  workspaceId: string;
  nanoId: string;
  title?: string;
  favicon?: string;
  urlSlug?: string;
  description?: string;
  googleTagManagerId?: string;
}
export const updatePublishSettings = async ({
  urlSlug,
  googleTagManagerId,
  ...rest
}: updatePageParams): Promise<unknown> => {
  return await updatePage(rest.workspaceId, rest.nanoId, {
    ...rest,
    ...(googleTagManagerId && { gtmId: googleTagManagerId }),
    definition: urlSlug,
  });
};

export const updatePageStatus = async (nanoId: string, status: string): Promise<void> => {
  await callGraphQl({
    query: `
    mutation UpdateEntityStatus($nanoId: String!, $status: String!) {
      updateEntityStatus(nanoId: $nanoId, type: "PAGE", status: $status) {
        ${expectedEntities}
      }
    }
    `,
    variables: { nanoId, status },
  });
};

export const updatePageContent = async (nanoId: string, content: string): Promise<void> => {
  await callGraphQl({
    query: `
    mutation UpdateEntityContent($nanoId: String!, $content: String) {
      updateEntityContent(nanoId: $nanoId, type: "PAGE", content: $content) {
        ${expectedEntities}
      }
    }
    `,
    variables: { nanoId, content },
  });
};

export const subscribeForStatus = async (
  nanoId: string,
  callback: (arg: ParsedLandingpageObject, subscribeResult: Subscribe) => void,
) => {
  const subscriptionOptions = {
    query: `
      subscription OnUpdateEntity($nanoId: String!) {
        onUpdateEntity(nanoId: $nanoId) {
          nanoId
          status
        }
      }
    `,
    variables: { nanoId },
  };

  const subscribeResult = subscribeGraphQl<OnUpdateEntitySubscription>(
    subscriptionOptions,
  ).subscribe({
    next: (value) => {
      callback(value.data.onUpdateEntity as ParsedLandingpageObject, subscribeResult);
    },
    error: (error) => {
      console.error('Subscription error:', error);
      // Handle subscription error here
    },
    complete: () => {
      console.log('Subscription complete');
      // Handle subscription completion here
    },
  });

  return subscribeResult;
};
